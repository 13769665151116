<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!-- eslint-disable vue/no-deprecated-filter -->
<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container
    id="iiotAddEditNodeContainer"
    fill-height
    class="pt-6 pl-6"
    fluid
  >
    <v-row>
      <v-col>
        <div class="title">
          <h1 v-if="!isUpdate">
            {{
              $t("nodes.addEditNode.title", {
                operation: $t("nodes.addEditNode.add"),
              })
            }}
          </h1>
          <h1 v-else>
            {{
              $t("nodes.addEditNode.title", {
                operation: $t("nodes.addEditNode.update"),
              })
            }}
          </h1>
          <v-divider />
        </div>
        <v-form
          id="iiotAddEditNodeForm"
          ref="form"
          @submit.stop.prevent="submitForm()"
        >
          <v-col>
            <v-row>
              <v-col cols="12" lg="4">
                <v-text-field
                  id="iiotAddEditNodeName"
                  v-model="node.name"
                  :label="$t('nodes.addEditNode.name')"
                  :rules="[rules.required, rules.nodeNamePattern]"
                  :disabled="isEditAllowed"
                  :maxlength="MAX_LENGTH_NAME"
                  class="mt-0"
                  autofocus
                  required
                  validate-on-blur
                />
                <v-text-field
                  id="iiotAddEditNodeSecureId"
                  v-model="node.secureId"
                  :label="$t('nodes.addEditNode.secureId')"
                  :rules="[
                    rules.required,
                    rules.securityIdPattern,
                    rules.securityLength,
                  ]"
                  :maxlength="MAX_LENGTH_SECURE_ID"
                  :counter="CHARACTER_COUNTER.SECUREID"
                  :disabled="isEditAllowed"
                  class="text-transform pt-6"
                  validate-on-blur
                />
                <v-text-field
                  id="iiotAddEditNodeSerialNo"
                  v-model="node.serialNumber"
                  :label="$t('nodes.addEditNode.serialNumber')"
                  :rules="[
                    rules.required,
                    rules.serialNumberPattern,
                    rules.serialLength,
                  ]"
                  :maxlength="SERIAL_NUMBER_LENGTH"
                  :counter="CHARACTER_COUNTER.SERIALNUMBER"
                  :disabled="isEditAllowed"
                  class="text-transform pt-6"
                  validate-on-blur
                />
                <v-tooltip
                  bottom
                  :disabled="
                    nameOfTimezone &&
                    nameOfTimezone.length <= MAX_LENGTH_TIMEZONE_FIELD
                  "
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-text-field
                        v-if="isUpdate && timezone"
                        id="iiotAddEditNodeTimeZone"
                        :v-model="nameOfTimezone"
                        :value="nameOfTimezone"
                        :label="$t('nodes.addEditNode.timeZone')"
                        :readonly="true"
                        :disabled="true"
                        class="pt-6"
                        validate-on-blur
                      />
                    </div>
                  </template>
                  <span>{{ nameOfTimezone }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" lg="4">
                <v-combobox
                  id="iiotAddEditNodeLabelsInput"
                  ref="chips"
                  v-model="selectedLabels"
                  :items="transformedLabels"
                  :cache-items="false"
                  :search-input.sync="search"
                  :single-line="true"
                  :multiple="true"
                  :dense="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="isEditAllowed"
                  :label="$t('addUpdateNode.labels')"
                  class="font-size"
                  flat
                  hide-no-data
                  hide-details
                  hide-selected
                  chips
                  @input="search = null"
                  @change="validateLabel"
                >
                  <template v-slot:selection="data">
                    <v-chip label small>
                      <span class="mb-2 px-2">
                        {{
                          data.item.length > 30
                            ? data.item.substring(0, 30) + "..."
                            : data.item
                        }}
                      </span>
                      <v-icon small @click="removeChip(data.item)">
                        mdi-close
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:append-item>
                    <div v-intersect="endIntersect" />
                  </template>
                  <v-tooltip slot="append" top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="cursor-help mt-3"
                        color="primary"
                        v-on="on"
                      >
                        info
                      </v-icon>
                    </template>
                    <span>{{ $t("nodes.addEditNode.labelInfo1") }}</span
                    ><br />
                    <span>{{ $t("nodes.addEditNode.labelInfo2") }}</span
                    ><br />
                    <span>{{ $t("nodes.addEditNode.labelInfo3") }}</span
                    ><br />
                    <span>{{ $t("nodes.addEditNode.labelInfo4") }}</span>
                  </v-tooltip>
                </v-combobox>
                <v-select
                  id="iiotAddEditNodeHardwareSelector"
                  v-model="node.model"
                  :items="hardware"
                  :disabled="isUpdate"
                  :label="node.model.name"
                  :single-line="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="font-size mt-9"
                  item-value="model"
                  item-text="name"
                  return-object
                  @change="setHardware"
                >
                  <template v-slot:item="data">
                    {{ data.item.name }}
                  </template>
                </v-select>
                <v-text-field
                  v-if="isUpdate"
                  id="iiotAddEditNodeVersion"
                  v-model="node.currentFWVersion"
                  :single-line="true"
                  class="mt-4"
                  disabled
                  label="Version"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <remote-connection-list
                  id="iiotAddEditNodeRemoteConnectionList"
                  :model="node"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col class="pl-0 pr-0" cols="12">
                <div>
                  <nerve-button
                    id="iiotAddEditNodeCancel"
                    :text="$t('baseForm.cancelBtn')"
                    type-of-btn="cancel"
                    size="normal"
                    class="mr-5 ml-0"
                    @click-event="cancel()"
                  />
                  <nerve-button
                    v-if="
                      (!isUpdate && !canAccess('UI_NODE:EDIT')) ||
                      (isUpdate && canAccess('UI_NODE:EDIT')) ||
                      (!isUpdate && canAccess('UI_NODE:CREATE'))
                    "
                    id="iiotAddEditNodeSave"
                    :text="
                      isUpdate
                        ? $t('baseForm.updateBtn')
                        : $t('baseForm.saveBtn')
                    "
                    type-of-btn="action"
                    size="normal"
                    type="submit"
                    class="mr-5 ml-0"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { NerveButton } from 'nerve-ui-components';
import listOfTimezones from 'nerve-ui-components/components/helpers/constants/listOfTimezones.json';
import {
  CHARACTER_COUNTER,
  SERIAL_NUMBER_LENGTH,
  URL_LENGTH,
  VALIDATION_REGEX,
  MAX_LENGTH_SECURE_ID,
  MAX_LENGTH_NAME,
  MAX_LENGTH_TIMEZONE_FIELD,
} from '@/constants';
import RemoteConnectionList from '@/components/remote-connection/RemoteConnectionList.vue';
import Logger from '@/utils/logger';

export default {
  components: { RemoteConnectionList, NerveButton },
  data: () => ({
    SERIAL_NUMBER_LENGTH,
    CHARACTER_COUNTER,
    URL_LENGTH,
    MAX_LENGTH_SECURE_ID,
    MAX_LENGTH_NAME,
    values: [],
    MAX_LENGTH_TIMEZONE_FIELD,

    selectedLabels: [],
    transformedLabels: [],
    hardwareName: '',
    selectedChip: '',
    isUpdate: false,
    search: null,
    params: {
      search: '',
      itemsPerPage: 10,
      page: 1,
    },
    lastPageOfLabels: 1,
  }),

  computed: {
    labelsList() {
      return this.$store.getters['labels/labelsList'];
    },
    node() {
      return this.$store.getters['nodes/getNodeData'];
    },
    hardware() {
      const hwList = this.$store.getters['hardware/getHardwareList'];

      if (this.isUpdate) {
        return hwList;
      }
      // user cannot assign unkown-hardware-model when creating node
      return hwList.filter((hw) => hw.model !== 'unknown-hardware-model');
    },
    remoteConnectionsList() {
      return this.$store.getters['remote-connection/list'];
    },
    rules() {
      return {
        required: (value) => !!value || this.$t('nodes.addEditNode.required'),
        serialLength: (value) => value.length === SERIAL_NUMBER_LENGTH
          || this.$t('nodes.addEditNode.serialLength'),
        securityLength: (value) => value.length === MAX_LENGTH_SECURE_ID
          || this.$t('nodes.addEditNode.securityLength'),
        serialNumberPattern: (value) => VALIDATION_REGEX.SERIAL_NUMBER.test(value)
          || this.$t('nodes.addEditNode.invalidSerialNumber'),
        securityIdPattern: (value) => VALIDATION_REGEX.SECURITY_ID.test(value)
          || this.$t('nodes.addEditNode.invalidSecureId'),
        nodeNamePattern: (value) => VALIDATION_REGEX.NODE_NAME.test(value)
          || this.$t('nodes.addEditNode.invalidName'),
      };
    },
    isEditAllowed() {
      return this.isUpdate && !this.canAccess('UI_NODE:EDIT');
    },
    count() {
      return this.$store.getters['labels/count'];
    },
    timezone() {
      return this.$store.getters['nodes/getTimezoneName'];
    },
    nameOfTimezone() {
      const timezoneName = this.$store.getters['nodes/getTimezoneName'];
      const foundTimezoneInList = listOfTimezones.filter(
        (element) => element.value === timezoneName,
      );
      if (foundTimezoneInList && foundTimezoneInList[0]) {
        return foundTimezoneInList[0].text;
      }

      return timezoneName;
    },
  },
  watch: {
    labelsList() {
      if (!this.node.labels.length) {
        this.transformLabels();
      }
    },
    node() {
      this.transformLabels();
    },
    search(val) {
      if (val) {
        this.selectedChip = val;
        this.params.search = val;
        this.params.page = 1;
        this.$store.dispatch('labels/fetchLabels', this.params);
      }
    },
  },
  async mounted() {
    try {
      const route = window.location.pathname.split('/');
      this.nodeId = route[route.length - 1];
      this.isUpdate = this.nodeId !== 'new';
      await this.$store.dispatch('nodes/get_node_by_id', this.nodeId);
      await this.$store.dispatch('nodes/set_is_update_flag', this.isUpdate);
      await this.$store.dispatch('labels/fetchLabels');
      this.lastPageOfLabels = Math.ceil(this.count / 10);
      this.$store.dispatch('hardware/init_hardware_list');
      await this.$store.dispatch('remote-connection/fetch', this.node);
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.params.page >= this.lastPageOfLabels) {
          return;
        }
        this.params.page += 1;
        this.$store.dispatch('labels/fetchLabels', this.params);
      }
    },
    async removeChip(item) {
      const index = this.selectedLabels.indexOf(item);
      if (index >= 0) {
        this.selectedLabels.splice(index, 1);
      }
    },
    async submitForm() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.node.labels = await this.transformLabelsToOriginal();
        this.node.serialNumber = this.node.serialNumber.toUpperCase();
        if (this.isUpdate) {
          await this.$store.dispatch('nodes/update_node', {
            nodeData: this.node,
            rc: this.remoteConnectionsList,
          });
          await this.$store.dispatch(
            'utils/_api_request_handler/show_custom_toast',
            {
              text: 'nodes.addEditNode.updatedNode',
              color: 'success',
              showClose: true,
            },
          );
          this.$router.go(-1);
          return;
        }
        await this.$store.dispatch('nodes/create_new_node', { nodeData: this.node, rc: this.remoteConnectionsList });
        await this.$store.dispatch(
          'utils/_api_request_handler/show_custom_toast',
          {
            text: 'nodes.addEditNode.addedNode',
            color: 'success',
            showClose: true,
          },
        );
        this.$router.go(-1);
      } catch (e) {
        Logger.error(e);
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    setHardware(hardware) {
      this.node.model = hardware.model;
    },
    transformLabels() {
      // eslint-disable-next-line array-callback-return
      this.labelsList.map((el) => {
        this.transformedLabels.push(`${el.key}:${el.value}`);
      });
      if (this.node && this.node.labels && this.node.labels.length) {
        this.selectedLabels = [];
        // eslint-disable-next-line array-callback-return
        this.node.labels.map((el) => {
          this.selectedLabels.push(`${el.key}:${el.value}`);
        });
      }
    },
    async transformLabelsToOriginal() {
      const newSelectedLabels = [...this.selectedLabels];
      return newSelectedLabels.map((el) => {
        const words = el.split(':');
        const label = this.checkId(words[0], words[1]);
        return {
          _id: label?._id,
          key: words[0],
          value: words[1],
        };
      });
    },
    checkId(key, value) {
      return this.labelsList.find(
        (label) => label.key === key && label.value === value,
      );
    },
    async validateLabel(label) {
      const words = label[label.length - 1]
        ? label[label.length - 1].split(':')
        : this.selectedChip.split(':');
      if (
        this.transformedLabels.includes(label[label.length - 1])
        || !label.length
      ) {
        return;
      }
      if (!VALIDATION_REGEX.LABEL_REGEX.test(words[0])) {
        await this.showToast(this.selectedChip, 'invalidLabel');
      }
      if (
        this.labelsList.some(
          (el) => el.key.toLowerCase() === words[0].toLowerCase(),
        )
      ) {
        await this.showToast(label[label.length - 1], 'duplicateKey');
      }
      if (words.length !== 2 || words[0] === '' || words[1] === '') {
        await this.showToast(this.selectedChip, 'wrongFormatForLabel');
      }
    },
    async showToast(chip, text) {
      await this.removeChip(chip);
      await this.$store.dispatch(
        'utils/_api_request_handler/show_custom_toast',
        {
          text: `nodes.addEditNode.${text}`,
          color: 'red',
          showClose: true,
        },
      );
    },
  },
};
</script>

<style lang="scss">
.font-size {
  font-size: 20px !important;
}
#iiotAddEditNodeTimeZone {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
